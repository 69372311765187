import './Important.css';
import react from '../../../img/important/react.png';
import server from '../../../img/important/cloud-server.png';
import warning from '../../../img/important/page-speed.png';

function Important() {
  return (
    <div className="main__important">
      <h2 className="main-important-h2">
        <span>Чому варто звертатися до нас</span>
      </h2>

      <div className="main-important-blocks">
        <div className="main-important-block">
          <img src={react} alt="expert" className="expert" />
          <h3>Web розробка з нуля</h3>
          <p>Дає такі переваги:</p>
          <ul className="importantul">
            <li>
              повний контроль над архітектурою і функціоналом додатку, що
              дозволяє створювати рішення, максимально адаптовані до ваших
              потреб
            </li>
            <li>
              дозволяє створювати унікальні користувацькі інтерфейси і функції,
              які важко реалізувати за допомогою готових рішень
            </li>
            <li>
              легко масштабувати у майбутньому, що важливо для проєктів з
              перспективою зростання
            </li>
            <li>
              дозволяє впроваджувати власні заходи безпеки і контролювати всі
              аспекти захисту даних користувачів
            </li>
          </ul>
          <span className="">Наш стек React+Nodejs/Nestjs.</span>
        </div>
        <div className="main-important-block">
          <img src={server} alt="expert" className="expert" />
          <h3>Проєкт під ключ</h3>
          <ul className="importantul">
            <li>підбираємо хостинг оптимальний для даного проєкту. </li>
            <li>
              створений сайт завантажуємо на сервер, включаючи закордонні
              сервери.
            </li>
            <li>тестуємо роботу сайту на хостингу</li>
            <li>SSL сертифікат</li>
            <li>
              додатковий захист проєкту: зберігаємо проєкт на віддаленому
              приватному репозиторії на GitHub. Дає можливість незалежно від
              місця перебування або стану комп’ютера мати доступ до проєкту
            </li>
            <li> oплата здійснюється після створення сайту</li>
          </ul>
        </div>
        <div className="main-important-block">
          <img src={warning} alt="expert" className="expert" />
          <h3>Оптимізація</h3>
          <ul className="importantul">
            <li>
              розроблений сайт оптимізований під будь-який пристрій (комп’ютер,
              телефон, планшет)
            </li>
            <li>
              розроблений сайт оптимізований під будь-яку операційну систему
              (Windows, Linux, macOS)
            </li>
            <li>
              розроблений сайт оптимізований під SEO, стосується
              багатосторінкового сайту. Це можливість пошуку сайту за ключовими
              словами без google реклами.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Important;
