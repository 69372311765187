import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import { FiPhoneCall } from 'react-icons/fi';
import './LinkPhone.css';

function Phone() {
  return (
    <div className="header__phone">
      <Link to={'tel:+380950835934'} className="header__phone-link">
        <img
          title="phone"
          // src={Img_phone}
          alt="phone"
          className="header__phone-img"
        />
      </Link>

      <Link to={'tel:+380950835934'} className="">
        <p className="header-phone-item">+38 0950835934</p>
      </Link>
    </div>
  );
}
export function PhoneIcons(props) {
  const { fontSises } = props;
  return (
    <div className="header__phone">
      <Link to={'tel:+380950835934'} className="FiPhoneCall">
        <motion.p className="hidden" style={{ fontSize: fontSises }}>
          <FiPhoneCall />
        </motion.p>
      </Link>

      <Link to={'tel:+380950835934'} className="header__phone-link">
        <motion.p style={{ fontSize: fontSises }} className="header-phone-item">
          +38 0950835934
        </motion.p>
      </Link>
    </div>
  );
}
export function PhoneOnly() {
  return (
    <Link to={'tel:+380950835934'} className="">
      <p className="header__phone-only">+38 0950835934</p>
    </Link>
  );
}

export default Phone;
