import { motion } from 'framer-motion';
import './Zayavka.css';
import FormaZayavka from '../../Forma/Forma_zayavka';
import { useState } from 'react';

export function ZayavkaHeader({
  width,
  padding,
  fontSize,
  opacity,
  marginRight,
}) {
  const [isNotScroll, setNotScroll] = useState(false);

  const [isZayavka, setZayavka] = useState(true);
  if (isNotScroll) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'scroll';
  }

  const openZayavka = () => {
    setZayavka(!isZayavka);
  };
  const closeForm = () => {
    setZayavka(true);
  };
  return (
    <>
      <motion.div
        style={{ width, padding, fontSize, opacity, marginRight }}
        className="zayavka"
        onClick={() => {
          openZayavka();
          setNotScroll(true);
        }}
      >
        <div className="">Заявка</div>
      </motion.div>
      <div
        onClick={() => {
          closeForm();
          setNotScroll(false);
        }}
        className={isZayavka ? 'hidden' : 'showForma'}
      >
        <FormaZayavka closeForm={closeForm} setNotScroll={setNotScroll} />
      </div>
    </>
  );
}
function Zayavka({ opacity, width, padding, fontSize, marginTop }) {
  const [isNotScroll, setNotScroll] = useState(false);

  const [isZayavka, setZayavka] = useState(true);
  if (isNotScroll) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'scroll';
  }

  const openZayavka = () => {
    setZayavka(!isZayavka);
  };
  const closeForm = () => {
    setZayavka(true);
  };
  return (
    <>
      <motion.div
        style={{ opacity, width, padding, fontSize, marginTop }}
        className="zayavka"
        onClick={() => {
          openZayavka();
          setNotScroll(true);
        }}
      >
        Заявка
      </motion.div>
      <div
        onClick={() => {
          closeForm();
          setNotScroll(false);
        }}
        className={isZayavka ? 'hidden' : 'showForma'}
      >
        <FormaZayavka closeForm={closeForm} setNotScroll={setNotScroll} />
      </div>
    </>
  );
}
export default Zayavka;
