import { motion } from 'framer-motion';
import { useProject } from '../../../tools/ProviderContext';
import './Btn.css';

function BurgerBtnMenu({ marginTop, width, left, heightLinesBurgerMenu }) {
  const { openMenu, changeBurger, isBurgerMenu, notScrollBtn, isScrollBurger } =
    useProject();
  if (isScrollBurger) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'scroll';
  }
  return (
    <>
      <motion.button
        style={{ marginTop }}
        onClick={() => {
          changeBurger();
          openMenu();
          notScrollBtn();
        }}
        className={
          isBurgerMenu ? 'open header__burger-btn' : 'header__burger-btn'
        }
      >
        <motion.span
          style={{ height: heightLinesBurgerMenu }}
          className="header__burger-item"
        ></motion.span>
        <motion.span
          style={{ width, left, height: heightLinesBurgerMenu }}
          className="header__burger-item"
        ></motion.span>
        <motion.span
          style={{ height: heightLinesBurgerMenu }}
          className="header__burger-item"
        ></motion.span>
      </motion.button>
    </>
  );
}
export default BurgerBtnMenu;
