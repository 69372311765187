import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import foto1 from '../../img/articles/article1.jpg';
import foto2 from '../../img/articles/Article2.1.png';
import foto3 from '../../img/articles/article3.jpg';
import './General_img.css';

function Block() {
  return (
    <div className="block">
      <h3>Що таке Web-розробка з нуля?</h3>
    </div>
  );
}
function BlockOneImgP() {
  return (
    <div>
      <img src={foto1} alt="foto1" className="fotoImg" />
      <div className="special">
        Web-розробка з нуля означає створення веб-сайту або веб-додатку спочатку
        без використання готових шаблонів або фреймворків. Це дизайн та
        верстання сторінок, робота з клієнтською та серверною частиною,
        конфігурація серверів.
        <p>Ось основні аспекти і переваги веброзробки з нуля: </p>
        <ul>
          <li>
            Індивідуальний дизайн: Ви можете розробляти унікальний дизайн, який
            відповідає конкретним потребам і бренду вашого проекту. Не обмежені
            шаблонами, ви можете повністю контролювати зовнішній вигляд та
            поведінку вашого сайту або додатку.
          </li>
          <li>
            Оптимальна продуктивність: Розробка з нуля дозволяє підлаштовувати
            код під конкретні вимоги і уникати зайвого або неефективного коду,
            що підвищує загальну продуктивність і швидкість роботи програми.
          </li>
          <li>
            Максимальна гнучкість: Ви можете вибирати технології, бібліотеки та
            інструменти, які найкраще підходять для вашого проекту, без
            обмежень, які можуть існувати в готових рішеннях...
          </li>
          {/* <li>
            Глибше розуміння: Розробка з нуля дозволяє глибше зрозуміти всі
            аспекти вашого проекту, включаючи архітектуру, процеси взаємодії між
            компонентами і оптимізацію. Це може полегшити підтримку і розвиток
            вашого продукту в майбутньому.
          </li>
          <li>
            Краща безпека: Ваш код не базується на загальнодоступних шаблонах,
            тому є менша ймовірність використання вразливостей, які можуть бути
            відомі зловмисникам.
          </li>
          <p>
            Таким чином, розробка з нуля надає більше контролю, гнучкість і
            можливості для створення індивідуальних, оптимізованих та безпечних
            веб-додатків або сайтів...
          </p> */}
        </ul>
      </div>
    </div>
  );
}

function BlockOneCheck() {
  return (
    <div className="check">
      <Link to="/article1">Детальніше...</Link>
    </div>
  );
}
function BlockOneFromArticle1() {
  return (
    <div className="block_1">
      <Block />
      <BlockOneImgP />
      <BlockOneCheck />
    </div>
  );
}
function BlockTwo() {
  return (
    <div className="block">
      <h3>React, для чого ця бібліотека?</h3>
    </div>
  );
}
function BlockTwoImgP() {
  return (
    <div>
      <img src={foto2} alt="foto9" className="fotoImg" />
      <div className="special">
        <p>
          React — це популярна JavaScript бібліотека для створення
          користувацьких інтерфейсів (UI). Вона була розроблена Facebook і
          відкрита для загального користування у 2013 році. React фокусується на
          компонентному підході до розробки інтерфейсів, що полегшує створення
          складних і динамічних додатків.
        </p>
        <p>
          React спрощує процес створення складних інтерфейсів, забезпечує швидке
          оновлення і відображення змін без перезавантаження сторінки.
        </p>
        <p>Ось кілька ключових аспектів та переваг React:</p>
        <ul>
          <li>
            Компонентний підхід: React дозволяє розділити інтерфейс на невеликі,
            незалежні компоненти, які можна повторно використовувати. Це робить
            код більш організованим і зручним для управління.
          </li>
          <li>
            Віртуальний DOM: React використовує віртуальний DOM для оптимізації
            оновлень. Коли стан компоненту змінюється, React створює віртуальний
            DOM і порівнює його з попереднім. Це дозволяє мінімізувати кількість
            реальних змін у DOM, що підвищує продуктивність...
          </li>
          {/* <li>
            JSX: React використовує JSX, розширення JavaScript, яке дозволяє
            писати HTML-подібний код у JavaScript. Це спрощує створення
            компонентів і робить код більш читабельним.
          </li>
          <li>
            Односпрямований потік даних: Дані у React передаються з батьківських
            компонентів до дочірніх через пропси (props), що робить управління
            станом більш передбачуваним...
          </li> */}
        </ul>
      </div>
    </div>
  );
}
function BlockTwoCheck() {
  return (
    <div className="check">
      <Link to="/article2">Детальніше...</Link>
    </div>
  );
}
function BlockTwoFromArticle2() {
  return (
    <div className="block_2">
      <BlockTwo />
      <BlockTwoImgP />
      <BlockTwoCheck />
    </div>
  );
}
function Block3() {
  return (
    <div className="block">
      <h3>SEO для просування бізнесу</h3>
    </div>
  );
}
function BlockThreeImgP() {
  return (
    <div>
      <img src={foto3} alt="foto1" className="fotoImg" />
      <div className="special">
        <p>
          SEO (Search Engine Optimization) — це комплекс заходів і стратегій,
          спрямованих на покращення видимості веб-сайту у пошукових системах,
          таких як Google, Bing, Yahoo та інші.
        </p>
        <p>
          Мета SEO — залучити більше органічного (неоплаченого) трафіку на сайт,
          підвищуючи його позиції у результатах пошуку за певними ключовими
          словами або фразами.
        </p>
        <p>Основні компоненти SEO:</p>
        <ol>
          <li>
            Технічне SEO: Включає оптимізацію технічних аспектів сайту, таких як
            структура URL, швидкість завантаження сторінок, мобільна
            оптимізація, правильне використання тегів HTML (наприклад,
            заголовків, мета-тегів), створення XML-карти сайту, налаштування
            файлу robots.txt і забезпечення безпеки (наприклад, використання
            HTTPS).
          </li>
          <li>
            Оптимізація контенту (On-Page SEO): Передбачає створення і
            оптимізацію контенту на сайті. Важливі аспекти включають
            використання релевантних ключових слів у заголовках, підзаголовках,
            тексті, мета-описах і альт-тегах для зображень. Важливо створювати
            якісний, унікальний і корисний контент, який відповідає на запити
            користувачів...
          </li>
          {/* <li>
            Зовнішня оптимізація (Off-Page SEO): Зосереджена на отриманні
            зовнішніх посилань (беклінків) з інших авторитетних веб-сайтів. Це
            підвищує авторитет і довіру до вашого сайту в очах пошукових систем.
            Зовнішня оптимізація також включає роботу з соціальними медіа і
            управління репутацією в Інтернеті.{' '}
          </li>
          <li>
            Локальне SEO: Важливо для бізнесів, які обслуговують певну
            географічну область. Локальне SEO включає оптимізацію профілю в
            Google My Business, отримання відгуків клієнтів, використання
            локальних ключових слів і забезпечення правильно...
          </li> */}
        </ol>
      </div>
    </div>
  );
}
function BlockThreeCheck() {
  return (
    <div className="check">
      <Link to="/article3">Детальніше...</Link>
    </div>
  );
}
function BlockOneFromArticle3() {
  return (
    <div className="block_3">
      <Block3 />
      <BlockThreeImgP />
      <BlockThreeCheck />
    </div>
  );
}
function SwiperArticles() {
  return (
    <Swiper
      navigation
      effect={'coverflow'}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={'auto'}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      pagination={{ type: 'fraction' }}
      modules={[EffectCoverflow, Navigation]}
      className="mySwiper"
    >
      <SwiperSlide>
        <BlockOneFromArticle1 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockTwoFromArticle2 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle3 />
      </SwiperSlide>
    </Swiper>
  );
}
export default SwiperArticles;
