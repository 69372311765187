import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Articles.css';
import quilon from '../../../img/articles/article3.jpg';
import quilon2 from '../../../img/articles/article3.2.jpg';
import { Cataloge } from './1_Article';
import WhatsApp from '../../../WhatsUp/WhatsappLink';
import Connection from '../../1_Header/Сonnection/Connection';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>UA</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang && <Link to="/en/article2">EN</Link>}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>SEO для просування бізнесу</h2>

            <p>
              SEO (Search Engine Optimization) — це комплекс заходів і
              стратегій, спрямованих на покращення видимості веб-сайту у
              пошукових системах, таких як Google, Bing, Yahoo та інші.
            </p>
            <p>
              Мета SEO — залучити більше органічного (неоплаченого) трафіку на
              сайт, підвищуючи його позиції у результатах пошуку за певними
              ключовими словами або фразами.
            </p>
            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>Основні компоненти SEO:</p>
            <ol>
              <li>
                Технічне SEO: Включає оптимізацію технічних аспектів сайту,
                таких як структура URL, швидкість завантаження сторінок,
                мобільна оптимізація, правильне використання тегів HTML
                (наприклад, заголовків, мета-тегів), створення XML-карти сайту,
                налаштування файлу robots.txt і забезпечення безпеки (наприклад,
                використання HTTPS).
              </li>
              <li>
                Оптимізація контенту (On-Page SEO): Передбачає створення і
                оптимізацію контенту на сайті. Важливі аспекти включають
                використання релевантних ключових слів у заголовках,
                підзаголовках, тексті, мета-описах і альт-тегах для зображень.
                Важливо створювати якісний, унікальний і корисний контент, який
                відповідає на запити користувачів.
              </li>
              <li>
                Зовнішня оптимізація (Off-Page SEO): Зосереджена на отриманні
                зовнішніх посилань (беклінків) з інших авторитетних веб-сайтів.
                Це підвищує авторитет і довіру до вашого сайту в очах пошукових
                систем. Зовнішня оптимізація також включає роботу з соціальними
                медіа і управління репутацією в Інтернеті.{' '}
              </li>
              <li>
                Локальне SEO: Важливо для бізнесів, які обслуговують певну
                географічну область. Локальне SEO включає оптимізацію профілю в
                Google My Business, отримання відгуків клієнтів, використання
                локальних ключових слів і забезпечення правильно...
              </li>
            </ol>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />
            <p> Чому SEO важливе:</p>
            <ul>
              <li>
                {' '}
                Підвищення видимості і трафіку: Високі позиції у пошукових
                системах призводять до збільшення кількості відвідувачів на ваш
                сайт. - Довгострокова ефективність: На відміну від платної
                реклами, результати SEO можуть бути тривалими і стабільними.{' '}
              </li>
              <li>
                Покращення користувацького досвіду: Оптимізація сайту для
                пошукових систем часто також покращує загальний користувацький
                досвід, що може призвести до збільшення конверсій.
              </li>
              <li>
                Конкурентна перевага: Сайти з кращою SEO-оптимізацією мають
                конкурентну перевагу у пошукових системах.
              </li>
            </ul>
            <p>
              Таким чином, SEO є критично важливим для залучення органічного
              трафіку, підвищення впізнаваності бренду і досягнення
              довгострокового успіху в Інтернеті.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function Article3() {
  return (
    <div className="body_wrapper">
      <Header />

      {/* <ButtomsLang /> */}
      <Connection />
      <Quilon />
      <Footer />
    </div>
  );
}
export default Article3;
