import SwiperArticles from '../../Swiper/Swiper';
import './Article.css';

function Articles() {
  return (
    <>
      <div className="main__second-services main-second">
        <div className="main-title">
          <p>Рекомендовані статті</p>
          <h2>
            Все про Web-розробку з нуля, SEO просування та Google рекламу...
          </h2>
        </div>
        <SwiperArticles />
      </div>
    </>
  );
}
export default Articles;
