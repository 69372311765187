import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { useProject } from '../../../tools/ProviderContext';
// import css from './Nav.module.css';
import './Nav.css';
import Zayavka from '../Zayavka/Zayavka';
import BurgerBtnMenu from '../BurgerBtnMenu/BurgerBtnMenu';
import { useState, useLayoutEffect } from 'react';

function MenuItem({ namePage, link }) {
  return (
    <NavLink className="menu__link" to={link}>
      {namePage}
    </NavLink>
  );
}

function Menu(props) {
  const { isBurgerMenu, closeBurgerMenu, notScrollBtn } = useProject();
  const [isMenu, setMenu] = useState(true);
  const {
    opacity,
    width,
    padding,
    fontSize,
    marginTop,
    widthLineBurgerMenu,
    left,
    heightLinesBurgerMenu,
    displayMenu,
  } = props;

  useLayoutEffect(() => {
    if (window.innerWidth < 900) {
      setMenu(false);
    } else {
      setMenu(true);
    }
  }, []);
  const MenuIntems = [
    { namePage: 'Головна', link: '/', class: 'menu__link', id: uuidv4() },
    { namePage: 'Про нас', link: '/about', class: 'menu__link', id: uuidv4() },
    { namePage: 'Послуги', link: '/price', class: 'menu__link', id: uuidv4() },
    { namePage: 'Блог', link: '/article1', class: 'menu__link', id: uuidv4() },
    {
      namePage: 'Контакти',
      link: '/contact',
      class: 'menu__link',
      id: uuidv4(),
    },
  ];

  return (
    <>
      {isMenu ? (
        <>
          <motion.div
            style={{ opacity, display: displayMenu }}
            className="menuzayavka"
          >
            <nav className={isBurgerMenu ? 'open menu' : 'menu'}>
              <ul className={'menu__list'}>
                {MenuIntems.map((item) => {
                  return (
                    <li key={item.id} className={'menu__item'}>
                      <MenuItem namePage={item.namePage} link={item.link} />
                    </li>
                  );
                })}
              </ul>
            </nav>
            <Zayavka
              width={width}
              padding={padding}
              fontSize={fontSize}
              opacity={opacity}
              marginTop={marginTop}
            />
            <BurgerBtnMenu
              heightLinesBurgerMenu={heightLinesBurgerMenu}
              left={left}
              width={widthLineBurgerMenu}
              marginTop={marginTop}
            />
          </motion.div>
        </>
      ) : (
        <>
          {' '}
          <div
            onClick={() => {
              closeBurgerMenu();
              notScrollBtn();
            }}
            className={isBurgerMenu ? 'drop' : 'hidden'}
          ></div>
          <motion.div style={{ opacity }} className="menuzayavka">
            <nav className={isBurgerMenu ? 'open menu' : 'menu'}>
              <ul className={'menu__list'}>
                {MenuIntems.map((item) => {
                  return (
                    <li
                      onClick={() => {
                        closeBurgerMenu();
                        notScrollBtn();
                      }}
                      key={item.id}
                      className={'menu__item'}
                    >
                      <MenuItem namePage={item.namePage} link={item.link} />
                    </li>
                  );
                })}
              </ul>
            </nav>
            <Zayavka
              width={width}
              padding={padding}
              fontSize={fontSize}
              opacity={opacity}
              marginTop={marginTop}
            />
            <BurgerBtnMenu
              heightLinesBurgerMenu={heightLinesBurgerMenu}
              left={left}
              width={widthLineBurgerMenu}
              marginTop={marginTop}
            />
          </motion.div>
        </>
      )}
    </>
  );
}

export default Menu;
