import React, { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import { LinkSocialContact } from '../../1_Header/SocialLinks/LinkSocial';
import foto from '../../../img/contact/planworks.jpg';
import './Contact.css';
import WhatsApp from '../../../WhatsUp/WhatsappLink';
import { FormaZayavkaContact } from '../../Forma/Forma_zayavka';
import Connection from '../../1_Header/Сonnection/Connection';
import Animate from '../../1_Header/animations/allanimations';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>UA</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang ? (
              <Link to="/en/contact">EN</Link>
            ) : (
              <Link to="/contact">RU</Link>
            )}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function ContactTable() {
  const width = '600px';
  const fontSize = '5rem';
  const height = '150';
  const className = 'svg-contact';
  const animate_heigth = 'animate__price';
  return (
    <div className="contact">
      <div className="contacts-title">
        {/* <h1>Контакти</h1> */}
        <Animate
          className={className}
          height={height}
          fontSize={fontSize}
          width={width}
          animate_heigth={animate_heigth}
        >
          Контакти
        </Animate>
      </div>
      <div className="container contacts__container">
        <div className="contacts__item">
          <div className="contacts__item-phone">
            <p>Номер телефону:</p>
            <Link to={'tel:+380950835934'} className="">
              <p className="header__phone-only">+38 0950835934</p>
            </Link>
          </div>
          <div className="contacts__item-phone">
            <p>Email:</p>
            <Link to={'mailto:info@topsite.com.ua'}>
              <h2 className="header-phone-item">info@topsite.com.ua</h2>
            </Link>
          </div>

          <div className="contacts__item-social">
            <p>Ми в соціальних мережах:</p>
            <div className="contacts__item-social-links">
              <LinkSocialContact />
            </div>
          </div>
          <div className="contacts__item-praca">
            <img src={foto} alt="foto" />
            <p>Ми працюємо:</p>
            <p>Понеділок - четвер з 7:00 до 22:00</p>
            <p>П'ятниця з 7:00 до 15:00</p>
            <p>Вихідний день субота</p>
            <p>Працюємо по всій Україні, а також Європі</p>
            <FormaZayavkaContact />
          </div>

          <div className="adress">Київ, Україна</div>
        </div>
      </div>
    </div>
  );
}
function Contact() {
  return (
    <div className="body_wrapper">
      <Header />

      {/* <ButtomsLang /> */}
      <ContactTable />
      <Connection />
      <Footer />
    </div>
  );
}
export default Contact;
