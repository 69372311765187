import { Link } from 'react-router-dom';
import './Connection.css';
import whatsapp from '../../../img/connection/whatsapp.png';
import viber from '../../../img/connection/viber.png';
import telegram from '../../../img/connection/telegram.png';
import phone from '../../../img/connection/phone.png';
import cross from '../../../img/connection/cross.png';
import { useState } from 'react';

function WhatsAppOnly() {
  return (
    <>
      <Link to="https://wa.me/380950835934">
        <img src={whatsapp} alt="WhatsApp" title="WhatsApp" />
      </Link>
    </>
  );
}
export function ViberOnly() {
  return (
    <>
      <Link to="viber://chat?number=380950835934">
        <img src={viber} alt="Viber" title="Viber" />
      </Link>
    </>
  );
}
function TelegramOnly() {
  return (
    <>
      <Link to="https://t.me/YuriiDzumelya">
        <img src={telegram} alt="Viber" title="Telegram" />
      </Link>
    </>
  );
}
function PhoneOnly() {
  return (
    <Link to={'tel:+380950835934'} className="">
      <img title="phone" src={phone} alt="phone" className="phoneonly" />
    </Link>
  );
}
function Connection() {
  const [isVisible, setVisible] = useState(false);
  const visible = () => {
    setVisible(!isVisible);
  };
  const closeBoxConnections = () => {
    setVisible(false);
  };

  return (
    <>
      <div className="calls">
        <div onClick={visible} className="connection">
          <span>Зв'язок</span>
        </div>
        <div className="allconnections">
          <div
            className={
              isVisible
                ? 'connection-whatsapp'
                : 'connection-whatsapp-novisible'
            }
          >
            <WhatsAppOnly />
          </div>
          <div
            className={
              isVisible ? 'connection-viber' : 'connection-viber-novisible'
            }
          >
            <ViberOnly />
          </div>
          <div
            className={
              isVisible
                ? 'connection-telegram'
                : 'connection-telegram-novisible'
            }
          >
            <TelegramOnly />
          </div>
          <div
            className={
              isVisible ? 'connection-phone' : 'connection-phone-novisible'
            }
          >
            <PhoneOnly />
          </div>
          <div
            onClick={closeBoxConnections}
            className={isVisible ? 'cross' : 'cross-novisible'}
          >
            <img src={cross} alt="закрити" />
          </div>
        </div>
      </div>
    </>
  );
}
export default Connection;
