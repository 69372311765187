import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Img_Facebook from '../../../img/links/facebook.png';
import Img_FacebookFooter from '../../../img/links/facebookFooter.png';
import Img_InstagramFooter from '../../../img/links/instagramFooter.png';
import Img_Instagram from '../../../img/links/instagram.jpg';
import Img_InstagramContact from '../../../img/links/instargamcontact.png';
import Img_FacebookContact from '../../../img/links/facebookcontact.png';

import './LinkSocial.css';

function LinkSocial(props) {
  const { height } = props;
  return (
    <div className="header__list-social-media">
      <div className="header__social-link-item">
        <Link
          to="https://www.instagram.com/top_site_com?igsh=MXAzcTN4NXpmdHRyNA=="
          title="instagram"
          alt="instagram"
          target="_blank"
          className="header__instargam"
        >
          <motion.img
            style={{ height }}
            src={Img_Instagram}
            alt="instagram"
            className="instagram"
          />
        </Link>
      </div>
      <div className="header__social-link-item">
        <Link
          to="https://www.facebook.com/profile.php?id=61561085115539"
          target="_blank"
          alt="facebook"
          title="facebook"
          className="header_facebook"
        >
          <motion.img
            style={{ height }}
            src={Img_Facebook}
            alt="facebook"
            className="facebook"
          />
        </Link>
      </div>
    </div>
  );
}
export function LinkSocialFooter() {
  return (
    <div className="header__list-social-mediaFooter">
      <div className="header__social-link-item">
        <Link
          to="https://www.instagram.com/top_site_com?igsh=MXAzcTN4NXpmdHRyNA=="
          title="instagram"
          alt="instagram"
          target="_blank"
          className="header__instargam"
        >
          <img
            src={Img_InstagramFooter}
            alt="instagram"
            className="instagramFooter"
          />
        </Link>
      </div>
      <div className="header__social-link-item">
        <Link
          to="https://www.facebook.com/profile.php?id=61561085115539"
          target="_blank"
          alt="facebook"
          title="facebook"
          className="header_facebook"
        >
          <img
            src={Img_FacebookFooter}
            alt="facebook"
            className="facebookFooter"
          />
        </Link>
      </div>
    </div>
  );
}
export function LinkSocialContact() {
  return (
    <div className="header__list-social-mediaFooter">
      <div className="header__social-link-item">
        <Link
          to="https://www.instagram.com/top_site_com?igsh=MXAzcTN4NXpmdHRyNA=="
          title="instagram"
          alt="instagram"
          target="_blank"
          className="header__instargam"
        >
          <img
            src={Img_InstagramContact}
            alt="instagram"
            className="instagramFooter"
          />
        </Link>
      </div>
      <div className="header__social-link-item">
        <Link
          to="https://www.facebook.com/profile.php?id=61561085115539"
          target="_blank"
          alt="facebook"
          title="facebook"
          className="header_facebook"
        >
          <img
            src={Img_FacebookContact}
            alt="facebook"
            className="facebookFooter"
          />
        </Link>
      </div>
    </div>
  );
}
export default LinkSocial;
