import React, { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import foto from '../../../img/about/about.jpg';
import foto2 from '../../../img/about/about2.jpg';
import './About.css';

import Connection from '../../1_Header/Сonnection/Connection';
import Animate from '../../1_Header/animations/allanimations';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  const closeLang = () => {
    setLang(false);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>UA</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang closeLang={closeLang} isLang={isLang}>
            {isLang ? (
              <Link to="/en/about">EN</Link>
            ) : (
              <Link to="/about">RU</Link>
            )}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function AboutTable() {
  const width = '950px';
  const fontSize = '4.5rem';
  const height = '150';
  const className = 'svg-about';
  const animate_heigth = 'animate__about';
  return (
    <div className="about">
      <div className="about-title">
        <Animate
          className={className}
          height={height}
          fontSize={fontSize}
          width={width}
          animate_heigth={animate_heigth}
        >
          Компанія TopSite
        </Animate>
      </div>
      <div className="container about-container">
        <div className="about__content">
          <div className="about__content-text">
            <h2>Ми раді вітати вас на нашому сайті!</h2>
            <h1>
              Компанія "TopSite" – це компанія у сфері веб-розробки, яка
              пропонує широкий спектр послуг зі створення сайтів.
            </h1>

            <p>
              Наша команда має значний досвід у створенні лендінгів,
              односторінкових та багатосторінкових сайтів, які допомагають
              бізнесам досягати своїх цілей. Наші рішення поєднують в собі
              привабливий дизайн, функціональність та зручність використання.
            </p>
            <div className="about__content-img">
              <img src={foto} alt="О нас" />
            </div>
            <p>
              Однією з основних переваг нашої команди є індивідуальний підхід до
              кожного клієнта. Ми розуміємо, що кожен бізнес має свої унікальні
              потреби та цілі, тому наші фахівці уважно вивчають вимоги клієнтів
              і створюють сайти, які ідеально відповідають їхнім потребам. Ми
              також забезпечуємо високий рівень підтримки на всіх етапах
              розробки, аж до запуску готового продукту.
            </p>

            <p>
              Ми використовуємо мову програмування javascript, яка є лідером у
              веб-розробці, що дозволяє нам забезпечувати високу якість продукту
              та швидкість розробки. Ми постійно стежимо за новими тенденціями у
              веб-розробці та впроваджуємо їх у наші проєкти, що дозволяє нашим
              клієнтам залишатися конкурентоспроможними на ринку. На сьогодні ми
              на стороні клієнта використовуємо React, а на стороні сервера
              Nodejs.
            </p>
            <p>
              Ми також особливо акцентуємо увагу на адаптивності наших сайтів. У
              світі, де все більше людей використовують мобільні пристрої для
              доступу до інтернету, важливо, щоб сайт виглядав і функціонував
              однаково добре на всіх платформах. Наші спеціалісти забезпечують,
              щоб кожен сайт, був повністю адаптивним та зручним для
              користувачів на різних пристроях.
            </p>
            <div className="about__content-img">
              <img src={foto2} alt="О нас" />
            </div>
            <p>
              Ми надаємо комплексні послуги з SEO просування, включаючи аналіз
              ключових слів, оптимізацію контенту, технічну оптимізацію сайту,
              побудову зовнішніх посилань та моніторинг результатів для
              покращення видимості вашого сайту в пошукових системах.
            </p>
            <p>
              Ми надаємо комплексні послуги з Google реклами, включаючи
              налаштування рекламних кампаній, підбір ключових слів, створення
              ефективних оголошень, оптимізацію ставок та аналіз результатів для
              досягнення максимального охоплення та конверсій.
            </p>
            <p>
              Компанія "TopSite" завжди орієнтована на результат. Ми розуміємо,
              що головна мета нашої роботи – допомогти нашим клієнтам досягати
              успіху в їхній діяльності. Тому ми прагнемо створювати не просто
              красиві сайти, а ефективні інструменти для розвитку бізнесу. З
              нами ваш веб-проект завжди буде на висоті. Запрошуємо до
              співпраці!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function About() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        {/* <ButtomsLang /> */}
        <Connection />
        <AboutTable />
        <Footer />
      </div>
    </>
  );
}
export default About;
