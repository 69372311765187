import css from './Whoops404.module.css';
import { Link } from 'react-router-dom';

function Whoops404() {
  return (
    <div className={css.error404}>
      <h1 className={css.nofindresurs}>
        <span>Вказаний вами шлях недійсний або не знайдений...</span>
      </h1>
      <p>
        Щоб перейти на сайт, натисніть на посилання
        <span className={css.linkpath}>
          <Link to="/"> topsite.com.ua </Link>
        </span>
      </p>
    </div>
  );
}
export default Whoops404;
