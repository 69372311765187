import { motion, useScroll, useTransform } from 'framer-motion';
import './Header.css';
import Menu from '../Nav/Nav';
import Logo from '../Logo/Logo';
import { PhoneIcons } from '../LinkPhone/LinkPone';
import SocialLink from '../SocialLinks/LinkSocial';
import { ZayavkaHeader } from '../Zayavka/Zayavka';
import { useLayoutEffect, useState } from 'react';

function Header600() {
  const { scrollY } = useScroll();
  // height header
  const heightHeader = {
    heigth600: useTransform(scrollY, [0, 200], [180, 80]),
  };
  const paddingHeader = {
    padding600: useTransform(scrollY, [0, 200], ['20px', '1px']),
  };

  // phone and social
  const heightPhone = {
    height600: useTransform(scrollY, [0, 200], [35, 30]),
  };
  const fontSisesPhone = {
    fontSize600: useTransform(scrollY, [0, 200], ['16px', '14px']),
  };
  const scaleLogo = {
    scale600: useTransform(scrollY, [0, 200], [1, 0.7]),
  };
  const marginTopLogo = {
    margin600: useTransform(scrollY, [0, 200], ['1px', '-45px']),
  };
  const fontSizeZayavka = {
    fontSize600: useTransform(scrollY, [50, 200], ['14px', '12px']),
  };
  const paddingZayavka = {
    padding600: useTransform(scrollY, [50, 200], ['5px 10px', '3px 0px']),
  };
  const widthZayavka = useTransform(scrollY, [50, 200], ['90px', '80px']);
  const margingTopZayavka = useTransform(scrollY, [50, 200], ['0px', '-55px']);
  const widthLineBurgerMenu = useTransform(
    scrollY,
    [50, 200],
    ['30px', '20px']
  );
  const postionBurgerMenuLeft = useTransform(
    scrollY,
    [50, 200],
    ['5px', '14px']
  );
  const heightLinesBurgerMenu = useTransform(
    scrollY,
    [50, 200],
    ['4px', '3px']
  );
  return (
    <motion.div
      className="header"
      style={{
        height: heightHeader.heigth600,
        padding: paddingHeader.padding600,
      }}
    >
      <div className="header__container_links">
        <PhoneIcons
          height={heightPhone.height600}
          fontSises={fontSisesPhone.fontSize600}
        />
        <SocialLink height={heightPhone.height600} />
      </div>
      <div className="container header__container">
        <Logo marginTop={marginTopLogo.margin600} scale={scaleLogo.scale600} />
        <Menu
          heightLinesBurgerMenu={heightLinesBurgerMenu}
          left={postionBurgerMenuLeft}
          widthLineBurgerMenu={widthLineBurgerMenu}
          fontSize={fontSizeZayavka.fontSize600}
          width={widthZayavka}
          padding={paddingZayavka.padding600}
          marginTop={margingTopZayavka}
        />
      </div>
    </motion.div>
  );
}

function Header() {
  const { scrollY } = useScroll();
  const [isHeader, setHeader] = useState(true);
  useLayoutEffect(() => {
    if (window.innerWidth < 600) {
      setHeader(false);
    } else {
      setHeader(true);
    }
  }, []);
  // height header
  const heightHeader = {
    heigth1260: useTransform(scrollY, [0, 300], ['300px', '55px']),
  };
  const paddingHeader = {
    padding1260: useTransform(scrollY, [0, 200], ['30px', '1px']),
  };

  // phone and social
  const heightPhone = {
    height1260: useTransform(scrollY, [0, 300], [60, 50]),
  };
  const fontSisesPhone = {
    fontSize1260: useTransform(scrollY, [0, 300], ['22px', '18px']),
  };
  // logo and menu
  const opacityLogoMenu = {
    opacity1260: useTransform(scrollY, [0, 200], [1, 0]),
  };

  const scaleLogo = {
    scale1260: useTransform(scrollY, [0, 300], [1.3, 0.4]),
  };
  const marginTopLogo = {
    margin1260: useTransform(scrollY, [0, 300], ['1px', '-85px']),
  };
  // zayavka
  const widghtZayavkaHeader = {
    widht1260: useTransform(scrollY, [100, 250], ['0px', '120px']),
  };
  const fontSizeZayavka = {
    fontSize1260: useTransform(scrollY, [100, 250], ['0px', '15px']),
  };
  const paddingZayavka = {
    padding1260: useTransform(scrollY, [100, 250], ['0px 0px', '5px 10px']),
  };
  const opacityZayavka = {
    opacity1260: useTransform(scrollY, [0, 250], [0, 1]),
  };
  const marginRigthZayavka = {
    margin1260: useTransform(scrollY, [0, 250], ['0px', '10px']),
  };
  const displayMenu = useTransform(scrollY, [0, 150], ['flex', 'none']);
  return (
    <>
      {isHeader ? (
        <motion.div
          className="header"
          style={{
            height: heightHeader.heigth1260,
            padding: paddingHeader.padding1260,
          }}
        >
          <div className="header__container_links">
            <PhoneIcons
              height={heightPhone.height1260}
              fontSises={fontSisesPhone.fontSize1260}
            />
            <SocialLink height={heightPhone.height1260} />
            <ZayavkaHeader
              marginRight={marginRigthZayavka.margin1260}
              opacity={opacityZayavka.opacity1260}
              fontSize={fontSizeZayavka.fontSize1260}
              padding={paddingZayavka.padding1260}
              width={widghtZayavkaHeader.widht1260}
            />
          </div>
          <div
            style={{
              opacity: opacityLogoMenu.opacity1260,
            }}
            className="container header__container"
          >
            <Logo
              marginTop={marginTopLogo.margin1260}
              scale={scaleLogo.scale1260}
            />
            <Menu
              displayMenu={displayMenu}
              opacity={opacityLogoMenu.opacity1260}
            />
          </div>
        </motion.div>
      ) : (
        <Header600 />
      )}
    </>
  );
}
export default Header;
