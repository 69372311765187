import { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Price.css';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';

import foto1 from '../../../img/price/foto10.1.jpg';
import foto2 from '../../../img/price/devapp.jpg';
import foto3 from '../../../img/price/seo.jpg';
import foto4 from '../../../img/price/googleads.jpg';
import Animate from '../../1_Header/animations/allanimations';
import Connection from '../../1_Header/Сonnection/Connection';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>UA</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang ? (
              <Link to="/en/price">EN</Link>
            ) : (
              <Link to="/price">RU</Link>
            )}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

export function PriceTable() {
  const width = '420px';

  const fontSize = '6.375rem';
  const height = '180px';
  const letterSpacing = '11.8px';
  const className = 'svg';
  const animate_heigth = 'animate__price';
  return (
    <>
      <div className="price">
        <div className="price-title">
          {/* <h1>Ціни</h1> */}
          <Animate
            className={className}
            letterSpacing={letterSpacing}
            height={height}
            fontSize={fontSize}
            width={width}
            animate_heigth={animate_heigth}
          >
            Ціни
          </Animate>
        </div>
        <div className="price__items">
          <div className="price__item">
            <h3 className="price__item-title">
              Односторінковий сайт - Landing
            </h3>
            <img src={foto1} alt="Landing" className="price__item-img" />
            <ul className="price__item-list">
              <li>Web розробка з нуля</li>
              <li>Мова програмування javascript</li>
              <li>Розширена бібліотека React</li>
              <li>
                Оптимізація під будь-який пристрій (персональний комп'ютер,
                планшет, мобільний телефон)
              </li>
              <li>
                Оптимізація під будь-яку операційну систему (Windows, Linux,
                macOS)
              </li>
              <li>Завантаження на сервер</li>
              <li>Три місяці безкоштовної підтримки</li>
              <li>SSL сертифікат</li>

              <li>Проста анімація</li>
              <li>Строк виконання до 5 днів</li>
            </ul>
            <div className="price__item-price">
              <span>від 6000 грн</span>
            </div>
          </div>
          <div className="price__item">
            <h3 className="price__item-title">Багатосторінковий сайт</h3>
            <img src={foto2} alt="Landing" className="price__item-img" />
            <ul className="price__item-list">
              <li>Web розробка з нуля</li>
              <li>Всі функції односторінкового сайту - Landing</li>
              <li>Для створення окремих сторінок React Router</li>
              <li>
                Для кращої взаємодії між компонентами використовуємо React
                Context API або Redux
              </li>
              <li>
                Розроблений сайт оптимізований під SEO, прописуємо правильні
                семантичні html теги, метатеги та ін.
              </li>
              <li>Для серверної частини використовуємо Nodejs+NestJS</li>
              <li>Завантаження на сервер</li>
              <li>SEO просування 3 місяці безкоштовно</li>
              <li>Покращена анімація</li>
              <li>Строк виконання до 15 днів</li>
            </ul>
            <div className="price__item-price">
              <span>від 12000 грн</span>
            </div>
          </div>
          <div className="price__item">
            <h3 className="price__item-title">SEO просування</h3>
            <img src={foto3} alt="Landing" className="price__item-img" />
            <ul className="price__item-list">
              <p>
                SEO просування сайту (Search Engine Optimization) – комплекс
                заходів, спрямований на поліпшення видимості сайту в органічному
                пошуку
              </p>
              <p>SEO просування складається з кількох основних етапів: </p>
              <li>Це аналіз та дослідження ключових слів</li>
              <li>
                Оптимізація контенту на вебсайті під вибрані ключові слова
              </li>
              <li>
                Технічна оптимізація сайту, що включає покращення швидкості
                завантаження сторінок
              </li>
              <li>Cтворення якісного та релевантного контенту</li>
              <li>
                Побудова зовнішніх посилань для підвищення авторитетності сайту
              </li>
              <li>
                Оптимізація мета-тегів, таких як заголовки та опис, для
                підвищення клікабельності в результатах пошуку
              </li>
              <li>
                Покращення користувацького досвіду, зокрема, шляхом спрощення
                навігації та забезпечення безпеки сайту
              </li>
              <li>
                Використання соціальних мереж для збільшення трафіку та
                популяризації контенту
              </li>
              <li>
                Робота з локальним SEO для залучення клієнтів з певного регіону
              </li>
              <li>
                Підтримка постійної комунікації з аудиторією через блоги, новини
                та інші оновлення контенту
              </li>
              <p>
                SEO є одним з найбільш ефективних і найменш витратних методів
                залучення цільових користувачів на сайт
              </p>
            </ul>

            <div className="price__item-price">
              <span>від 2000 грн</span>
            </div>
          </div>
          <div className="price__item">
            <h3 className="price__item-title">Google реклама</h3>
            <img src={foto4} alt="Landing" className="price__item-img" />
            <ul className="price__item-list">
              <p>
                Контекстна реклама – один з каналів інтернет- маркетингу,
                націлений максимально охопити цільову аудиторію, залучити
                потенційних покупців і, відповідно, підвищити продажі
              </p>
              <p>Що ми робимо:</p>
              <li>
                Створюємо обліковий запис Google Ads, який необхідний для
                керування рекламними кампаніями
              </li>
              <li>
                Визначаємо рекламні кампанії, які можуть бути пошуковими,
                медійними, відео, торговими або кампаніями для додатків
              </li>
              <li>
                Формуємо групи оголошень, які містять одне або кілька рекламних
                оголошень і ключових слів
              </li>
              <li>
                Складємо ключові слова, за якими показуватимуться оголошення в
                пошукових кампаніях
              </li>
              <li>
                Формування бюджету та ставки, які визначають, скільки ви готові
                витратити на покази та кліки
              </li>
              <li>
                Аналітика та звітність, що дозволяють відстежувати ефективність
                рекламних кампаній та оптимізувати їх
              </li>
              <p>
                Перевага Google Ads полягає в точному таргетуванні, гнучкому
                бюджеті, швидких результатах, детальній аналітиці,
                різноманітності форматів, глобальному охваті та інтеграції з
                іншими сервісами Google
              </p>
            </ul>
            <div className="price__item-price">
              <span>від 5000 грн</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Price() {
  return (
    <div className="body_wrapper">
      <Header />
      {/* <ButtomsLang /> */}
      <PriceTable />
      <Connection />
      <Footer />
    </div>
  );
}
export default Price;
