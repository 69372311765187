import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Articles.css';
import quilon from '../../../img/articles/article2.jpg';
import quilon2 from '../../../img/articles/Article2.1.png';
import { Cataloge } from './1_Article';
import WhatsApp from '../../../WhatsUp/WhatsappLink';
import Connection from '../../1_Header/Сonnection/Connection';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>PL</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang && <Link to="/en/article1">EN</Link>}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>React, для чого ця бібліотека?</h2>
            <p>
              React — це популярна JavaScript бібліотека для створення
              користувацьких інтерфейсів (UI). Вона була розроблена Facebook і
              відкрита для загального користування у 2013 році. React
              фокусується на компонентному підході до розробки інтерфейсів, що
              полегшує створення складних і динамічних додатків.
            </p>
            <img className="quilon" src={quilon} title="quilon" alt="quilon" />
            <p>
              React спрощує процес створення складних інтерфейсів, забезпечує
              швидке оновлення і відображення змін без перезавантаження
              сторінки.
            </p>
            <p>Ось кілька ключових аспектів та переваг React:</p>
            <ul>
              <li>
                Компонентний підхід: React дозволяє розділити інтерфейс на
                невеликі, незалежні компоненти, які можна повторно
                використовувати. Це робить код більш організованим і зручним для
                управління.
              </li>
              <li>
                Віртуальний DOM: React використовує віртуальний DOM для
                оптимізації оновлень. Коли стан компоненту змінюється, React
                створює віртуальний DOM і порівнює його з попереднім. Це
                дозволяє мінімізувати кількість реальних змін у DOM, що підвищує
                продуктивність.{' '}
              </li>
              <li>
                JSX: React використовує JSX, розширення JavaScript, яке дозволяє
                писати HTML-подібний код у JavaScript. Це спрощує створення
                компонентів і робить код більш читабельним.
              </li>
              <li>
                Односпрямований потік даних: Дані у React передаються з
                батьківських компонентів до дочірніх через пропси (props), що
                робить управління станом більш передбачуваним
              </li>
            </ul>
            <img
              className="quilon2"
              src={quilon2}
              title="quilon"
              alt="quilon"
            />
            <p>Переваги використання React:</p>
            <ul>
              <li>
                Висока продуктивність: Завдяки віртуальному DOM і оптимізованому
                оновленню компонентів, React забезпечує високу продуктивність
                навіть у великих і складних додатках.
              </li>
              <li>
                Велика спільнота і екосистема: React має велику і активну
                спільноту розробників, що означає наявність безлічі ресурсів,
                бібліотек і інструментів, які можуть полегшити і прискорити
                розробку.
              </li>
              <li>
                SEO-оптимізація: React можна використовувати разом з
                інструментами для серверного рендерингу, такими як Next.js, що
                покращує SEO і швидкість завантаження сторінок.{' '}
              </li>
              <li>
                Кросплатформеність: Завдяки React Native, можна використовувати
                ті ж самі навички і компоненти для розробки мобільних додатків
                для iOS і Android.
              </li>
            </ul>
            <p>
              Використання React підходить для різних типів проектів, від
              невеликих додатків до великих корпоративних систем.
            </p>
            <p>
              {' '}
              Він часто використовується в поєднанні з іншими бібліотеками та
              фреймворками, такими як Redux для управління станом, React Router
              для управління маршрутизацією та Next.js для серверного
              рендерингу.
            </p>
            <p>
              Таким чином, React є потужним і гнучким інструментом для створення
              сучасних веб-додатків з високою продуктивністю і чудовим
              користувацьким досвідом.
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function Article2() {
  return (
    <div className="body_wrapper">
      <Header />

      {/* <ButtomsLang /> */}
      <Connection />
      <Quilon />
      <Footer />
    </div>
  );
}
export default Article2;
