import { createContext, useContext, useState } from 'react';

// створення контексту
const ProjectContext = createContext();

// створення хука
const useProject = () => useContext(ProjectContext);

// component
function ProviderContext({ children }) {
  const [isMenu, setIsMenu] = useState(false);
  const [isBurgerMenu, setIsBurgerMenu] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const [isLang, setLang] = useState(false);
  const [isViber, setIsViber] = useState(false);
  const [isScrollBurger, setScrollBurger] = useState(false);

  const notScrollBtn = () => {
    setScrollBurger(!isScrollBurger);
  };

  const changeLang = () => {
    setLang((isLang) => !isLang);
  };

  const openMenu = () => {
    setIsMenu(true);
  };
  const closeMenu = () => {
    setIsMenu(false);
  };

  const changeBurger = () => {
    setIsBurgerMenu((isBurgerMenu) => !isBurgerMenu);
  };
  const closeBurgerMenu = () => {
    setIsBurgerMenu(false);
  };
  return (
    <ProjectContext.Provider
      value={{
        isLang,
        changeLang,
        changeBurger,
        openMenu,
        closeMenu,
        closeBurgerMenu,
        isMenu,
        isBurgerMenu,
        isScroll,
        setIsScroll,
        setLang,
        isViber,
        setIsViber,
        notScrollBtn,
        isScrollBurger,
        setScrollBurger,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
}
export { ProviderContext, useProject };
