import './Services.css';
import fotoweb from '../../../img/Services/ui_uX.jpg';
import fotoweb2 from '../../../img/Services/website.jpg';
import main1 from '../../../img/Services/foto2.jpg';
import main2 from '../../../img/Services/foto1.jpg';
import main3 from '../../../img/Services/foto3.jpg';
import main4 from '../../../img/Services/foto4.jpg';
import { Link } from 'react-router-dom';

function Services() {
  return (
    <>
      <div className="main__first-services">
        <div className="main__first-title">
          <h1 className="hidden visible">
            Створення сайтів <br /> для бізнесу
          </h1>
          <img className="main__first-title-img" src={fotoweb} alt="fotoweb" />
          <div className="main__first-title-text">
            <h1 className="hidden2">
              Створення сайтів <br /> для бізнесу
            </h1>
            <h2>Web розробка сайту з нуля</h2>
            <h3>Гарантія якості та безпеки</h3>
          </div>
          <img
            className="main__first-title-img2"
            src={fotoweb2}
            alt="fotoweb2"
          />
        </div>

        <div className="main__services">
          <div className="main__services-item">
            <h2> Односторінковий сайт</h2>
            <p className="main-services-price">Landing page/React</p>
            <div className="main__services-img">
              <img src={main1} alt="photo1" />
            </div>

            <p className="main-services-price">від 6000 грн</p>
          </div>
          <div className="main__services-item">
            <h2>Багатосторінковий сайт</h2>
            <p className="main-services-price">Nodejs/React-Router</p>
            <div className="main__services-img">
              <img src={main2} alt="photo1" />
            </div>
            <p className="main-services-price">від 12000 грн</p>
          </div>
          <div className="main__services-item">
            <h2>SEO - пошукова оптимізація </h2>
            <p className="main-services-price">Просування без реклами</p>
            <div className="main__services-img">
              <img src={main3} alt="photo1" />
            </div>
            <p className="main-services-price">від 2000 грн</p>
          </div>
          <div className="main__services-item">
            <h2>Google реклама</h2>
            <p className="main-services-price">Google Ads/контекстна реклама</p>
            <div className="main__services-img">
              <img src={main4} alt="photo1" />
            </div>
            <p className="main-services-price">від 5000 грн</p>
          </div>
        </div>
        <div className="main__link-details">
          <Link to="/price">Детальніше...</Link>
        </div>
      </div>
    </>
  );
}
export default Services;
