import { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import './Articles.css';
import quilon from '../../../img/articles/article1.jpg';
import quilon2 from '../../../img/articles/article1.2.jpg';
import quilo3 from '../../../img/articles/article1.3.jpg';
import Connection from '../../1_Header/Сonnection/Connection';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>UA</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang && <Link to="/en/article3">EN</Link>}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}
export function Cataloge() {
  const [isScroll, setScroll] = useState(true);

  useEffect(() => {
    if (isScroll) {
      window.scroll({ top: -100, behavior: 'smooth' });
      setScroll(false);
    }
  }, [isScroll]);

  const toggle = () => {
    setScroll(true);
  };
  const toggle2 = () => {
    setScroll(true);
  };

  return (
    <div className="catalogs">
      <h3>Блог</h3>
      <ul>
        <li onClick={toggle}>
          <NavLink
            className={({ isActive }) =>
              isActive ? 'changeactivecatalog' : ''
            }
            to="/article1"
          >
            <span>Що таке Web-розробка з нуля?</span>
          </NavLink>
        </li>
        <li onClick={toggle2}>
          <NavLink
            className={({ isActive }) =>
              isActive ? 'changeactivecatalog' : ''
            }
            to="/article2"
          >
            <span>React, для чого ця бібліотека?</span>
          </NavLink>
        </li>
        <li onClick={() => setScroll(true)}>
          <NavLink
            className={({ isActive }) =>
              isActive ? 'changeactivecatalog' : ''
            }
            to="/article3"
          >
            SEO для просування бізнесу
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

function Quilon() {
  return (
    <>
      <div className="containerarticel">
        <div className="atricelandcatalog">
          <div className="articelquilon">
            <h2>Що таке Web-розробка з нуля?</h2>
            <img
              className="quilon"
              src={quilon}
              title="web-розробка"
              alt="web-розробка"
            />
            <p>
              Web-розробка з нуля означає створення веб-сайту або веб-додатку
              спочатку без використання готових шаблонів або фреймворків. Це
              дизайн та верстання сторінок, робота з клієнтською та серверною
              частиною, конфігурація серверів.
            </p>
            <img
              className="quilon"
              src={quilo3}
              title="web-розробка"
              alt="web-розробка"
            />
            <p>Ось основні аспекти і переваги веброзробки з нуля: </p>
            <ul>
              <li>
                Індивідуальний дизайн: Ви можете розробляти унікальний дизайн,
                який відповідає конкретним потребам і бренду вашого проекту. Не
                обмежені шаблонами, ви можете повністю контролювати зовнішній
                вигляд та поведінку вашого сайту або додатку.
              </li>
              <li>
                Оптимальна продуктивність: Розробка з нуля дозволяє
                підлаштовувати код під конкретні вимоги і уникати зайвого або
                неефективного коду, що підвищує загальну продуктивність і
                швидкість роботи програми.
              </li>
              <li>
                Максимальна гнучкість: Ви можете вибирати технології, бібліотеки
                та інструменти, які найкраще підходять для вашого проекту, без
                обмежень, які можуть існувати в готових рішеннях.
              </li>
              <li>
                Глибше розуміння: Розробка з нуля дозволяє глибше зрозуміти всі
                аспекти вашого проекту, включаючи архітектуру, процеси взаємодії
                між компонентами і оптимізацію. Це може полегшити підтримку і
                розвиток вашого продукту в майбутньому.
              </li>
              <li>
                Краща безпека: Ваш код не базується на загальнодоступних
                шаблонах, тому є менша ймовірність використання вразливостей,
                які можуть бути відомі зловмисникам.
              </li>
            </ul>
            <img
              className="quilon2"
              src={quilon2}
              title="web-розробка"
              alt="web-розробка"
            />
            <p>Ключові етапи веб-розробки:</p>
            <ul>
              <li>
                визначення функціональності, дизайну, технічних вимог і
                бізнес-цілі.
              </li>
              <li>
                аналіз дизайнерської концепції та втілення її в життя за
                допомогою розробки макета, але це завдання може виконувати і
                дизайнер;
              </li>
              <li>
                на цьому етапі ви створюєте код вашого веб-додатку або сайту.
                Використовуйте вибрані технології і фреймворки, дотримуйтеся
                кращих практик програмування і розробки. Розробка може включати
                фронтенд (HTML, CSS, JavaScript), бекенд (серверна частина, бази
                даних) і інтеграцію зовнішніх сервісів або API.
              </li>

              <li>
                робота з програмним забезпеченням, що включає розробку
                функціональних елементів або інтеграцію певних інструментів у
                вже наявну систему керування вмістом;
              </li>
              <li>
                після завершення розробки проведіть тестування вашого сайту або
                додатку. Відлагоджуйте помилки і переконайтеся, що веб-додаток
                працює як очікувалося в різних умовах і на різних пристроях.
                Важливо перевірити безпеку, продуктивність і сумісність з
                різними браузерами.
              </li>

              <li>запуск веб-проєкту на сервері;</li>
              <li>
                обслуговування проєкту загалом або його програмної складової...
              </li>
            </ul>
            <p>
              Таким чином, розробка з нуля надає більше контролю, гнучкість і
              можливості для створення індивідуальних, оптимізованих та
              безпечних веб-додатків або сайтів...
            </p>
          </div>
          <Cataloge />
        </div>
      </div>
    </>
  );
}

function Article1() {
  return (
    <div className="body_wrapper">
      <Header />

      {/* <ButtomsLang /> */}
      <Quilon />
      <Connection />
      <Footer />
    </div>
  );
}
export default Article1;
