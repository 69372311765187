import './allanimations.css';
function Animate({
  width,
  children,
  fontSize,
  height,
  letterSpacing,
  className,
  animate_heigth,
}) {
  return (
    <>
      <div className={animate_heigth}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          stroke="#2A4A73"
          width={width}
          height={height}
          className={className}
          fontSize={fontSize}
          letterSpacing={letterSpacing}
        >
          <g></g>
          <text x="0" y="50%">
            {children}
          </text>
        </svg>
      </div>
    </>
  );
}

export default Animate;
