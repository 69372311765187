import { MdOutlineEmail } from 'react-icons/md';
import { Link } from 'react-router-dom';
import './Email.css';

function Email() {
  return (
    <div className="email">
      <Link to={'mailto:info@topsite.com.ua'}>
        <p className="email__link">
          <MdOutlineEmail /> info@topsite.com.ua
        </p>
      </Link>
    </div>
  );
}
export default Email;
